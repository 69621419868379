const toilets = [...Array(7)].map((_,i) => require(`../../image/cataloge/toilets/${i}.jpg`))

export const ToiletData = [
    {
        id: 0,
        title: 'Санузел.',
        imgURL: toilets[0]
    },
    {
        id: 1,
        title: 'Проект от дизайнера Рувимской Ольги. Материал HPL Smart.',
        imgURL: toilets[1]
    },
    {
        id: 2,
        title: 'Санузел.',
        imgURL: toilets[2]
    },
    {
        id: 3,
        title: 'Санузел. Фасады cleaf древесный',
        imgURL: toilets[3]
    },
    {
        id: 4,
        title: 'Санузел. Пластик ЭГГЕР и AGT суперматовый',
        imgURL: toilets[4]
    },
    {
        id: 5,
        title: 'СНТ Культура. Фасады суперматовый Alvic. Indigo',
        imgURL: toilets[5]
    },
    {
        id: 6,
        title: 'Санузел.',
        imgURL: toilets[6]
    },
]