import React from "react"
import { Box, Container, Center, Image, Flex, Group, Text } from "@mantine/core"

import { IconBrandTelegram, IconBrandWhatsapp, IconMail } from "@tabler/icons-react"
import { changeColorButton4 } from "../../styles/style"

const AddressData = [
    {
        id: 0,
        title: '108811, Россия, Москва, поселение Московский, 22-ой км. Киевского шоссе, домовладение 4,'
    },
    {
        id: 1,
        title: 'Метро Румянцево'
    },
    {
        id: 2,
        title: 'Корпус В, вход №5, этаж 3, студия мебели “Амалия-home”'
    },
]

const IconData = [
    {
        id: 0,
        icon: <IconBrandTelegram size={24} color="#FFF"/>,
        link: 'https://t.me/Nadezda236'
    },
    {
        id: 1,
        icon: <IconBrandWhatsapp size={24} color="#FFF"/>,
        link: 'https://wa.me/+79993359600'
    },
    {
        id: 2,
        icon: <IconMail size={24} color="#FFF"/>,
        link: 'mailto:9637860116@mail.ru'
    },
]

interface ComponentProps {
    id: number,
    title: string,
}

const PropsContainer: React.FC<ComponentProps> = ({id, title}) => {
    return <Text key={id} ff={'Roboto Condensed'} fz={18} lh={'normal'} mt={'0.5rem'} color="#10222E">{title}</Text>
}

interface IconComponentProps {
    id: number,
    icon: JSX.Element,
    link: string
}

const IconPropsContainer: React.FC<IconComponentProps> = ({id, icon, link}) => {
    return (
        <Box key={id} bg={'#10222E'} sx={{
            borderRadius: 5,
            '&:hover': {
                animation: `${changeColorButton4} 750ms`,
                backgroundColor: '#73303F'
              }
            }} w={36} h={36} component="a" href={link}>
            <Box m={6}>{icon}</Box>
        </Box>
    )
}

export const SectionSeven: React.FC = (type: any) => {
    const qrCode = require('../../image/qr-code.png')
    return (
        <Box w={'100%'} h={'max-content'} bg={'#FFF'}>
            <Container maw={1180}>
                <Center>
                    <Box w={440} h={65} mt={'4rem'} sx={{borderBottomColor: '#10222E', borderBottomWidth: 4, borderBottomStyle: 'solid'}} className="sec-7-box">
                        <Center>
                            <Text ff={'Roboto Condensed'} fz={42} color='#10222E' fw={700} lh={'normal'} lts={2} className="sec-7-text">
                                Где нас найти?
                            </Text>
                        </Center>
                    </Box>
                </Center>
                <iframe title="Карта" src="https://yandex.ru/map-widget/v1/?um=constructor%3Aa7a51bba8ad7ad686649562150b1d3dee247a503ff1b8fab175fe55cf0edbf6f&amp;source=constructor" className="yandex-map"></iframe>
                <Group mt={'2rem'} pb={'4rem'} h={'max-content'} position="apart">
                    <Flex w={580} direction={'column'}>
                        <Text ff={'Roboto Condensed'} fz={24} fw={700} lh={'normal'} lts={2} mt={'1rem'} color="#10222E">Адрес:</Text>
                        {
                            AddressData.map((item) => (
                                <PropsContainer key={item.id} id={item.id} title={item.title} />
                            ))
                        }
                    </Flex>
                    <Flex direction={'column'} w={400}>
                        <Text ff={'Roboto Condensed'} fz={24} fw={700} lh={'normal'} lts={2} mt={'1rem'} color="#10222E">Связь напрямую:</Text>
                        <Group position="left" mt={'1rem'}>
                            {
                                IconData.map((item) => (
                                    <IconPropsContainer key={item.id} id={item.id} icon={item.icon} link={item.link}/>
                                ))
                            }
                        </Group>
                        <Text ff={'Roboto Condensed'} fz={24} fw={700} lh={'normal'} lts={2} mt={'3rem'} color="#10222E">Телефон:</Text>
                        <Text ff={'Roboto'} fz={18} fw={500} lh={'normal'} mt={'1rem'}>{'+7(999)335-96-00'}</Text>
                    </Flex>
                    <Flex direction={'column'} align={'center'} justify={'center'} w={'100%'} mt={'1rem'}>
                        <Image src={qrCode} maw={250} />
                        <Text ff={'Roboto'} fw={500} ta={'center'} w={'90%'} mx={'auto'} mt={'sm'} color='#10222E'>
                            QR-код для обратной связи
                        </Text>
                    </Flex>
                </Group>
            </Container>
        </Box>
    )
}